import {
  Badge,
  Button,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UnorderedList,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  getDoc,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AppLanguage from '../../common/AppLanguage';
import { useCountriesCollectionRef } from '../../common/collections/Countries';
import {
  DestinationDoc,
  useDestinationsCollectionRef,
} from '../../common/collections/Destinations';
import { UnsplashPicture } from '../../common/UnsplashPicture';
import CoverFormControl from '../../components/CoverFormControl';

export type Props = {
  initialValues: {
    countryId: string;
    countryName: Record<AppLanguage, string>;
    description: Record<AppLanguage, string>;
    labels: string[];
    name: Record<AppLanguage, string>;
    picture: null | UnsplashPicture;
  }
  onComplete: (destinationRef: DocumentReference<DestinationDoc>) => void;
};

export default function DestinationForm({ initialValues, onComplete }: Props) {
  const { t } = useTranslation('DestinationCreateScreen', { keyPrefix: 'DestinationForm' });

  const schema = useMemo(
    () => yup.object().shape({
      countryId: yup
        .string()
        .label(t('countryId.label'))
        .required(),
      countryName: yup
        .object()
        .label(t('countryName.label'))
        .required()
        .shape({
          ar: yup.string().required(),
          be: yup.string().required(),
          ca: yup.string().required(),
          de: yup.string().required(),
          en: yup.string().required(),
          es: yup.string().required(),
          fr: yup.string().required(),
          id: yup.string().required(),
          it: yup.string().required(),
          ko: yup.string().required(),
          ms: yup.string().required(),
          nl: yup.string().required(),
          pl: yup.string().required(),
          pt: yup.string().required(),
          ru: yup.string().required(),
          tr: yup.string().required(),
          uk: yup.string().required(),
          uz: yup.string().required(),
        }),
      description: yup
        .object()
        .label(t('description.label'))
        .required()
        .shape({
          ar: yup.string().required(),
          be: yup.string().required(),
          ca: yup.string().required(),
          de: yup.string().required(),
          en: yup.string().required(),
          es: yup.string().required(),
          fr: yup.string().required(),
          id: yup.string().required(),
          it: yup.string().required(),
          ko: yup.string().required(),
          ms: yup.string().required(),
          nl: yup.string().required(),
          pl: yup.string().required(),
          pt: yup.string().required(),
          ru: yup.string().required(),
          tr: yup.string().required(),
          uk: yup.string().required(),
          uz: yup.string().required(),
        }),
      labels: yup
        .array()
        .label(t('labels.label'))
        .of(
          yup
            .string()
            .label(t('labels.item.label'))
            .required(),
        )
        .required(),
      name: yup
        .object()
        .label(t('name.label'))
        .required()
        .shape({
          ar: yup.string().required(),
          be: yup.string().required(),
          ca: yup.string().required(),
          de: yup.string().required(),
          en: yup.string().required(),
          es: yup.string().required(),
          fr: yup.string().required(),
          id: yup.string().required(),
          it: yup.string().required(),
          ko: yup.string().required(),
          ms: yup.string().required(),
          nl: yup.string().required(),
          pl: yup.string().required(),
          pt: yup.string().required(),
          ru: yup.string().required(),
          tr: yup.string().required(),
          uk: yup.string().required(),
          uz: yup.string().required(),
        }),
      picture: yup
        .mixed<UnsplashPicture>()
        .label(t('picture.label'))
        .required()
        .nullable(),
    }),
    [t],
  );

  const destinationsCollectionRef = useDestinationsCollectionRef();
  const countriesCollectionRef = useCountriesCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      if (!values.picture) {
        return;
      }

      const destinationRef = doc(destinationsCollectionRef);

      const countryRef = doc(countriesCollectionRef, values.countryId);
      const countrySnap = await getDoc(countryRef);
      const countryDoc = countrySnap.data();

      if (!countrySnap.exists || !countryDoc) {
        throw new Error('Country not found');
      }

      await setDoc(
        destinationRef,
        {
          _v: 1,
          continentName: countryDoc.continentName,
          continentRef: countryDoc.continentRef,
          countryEmoji: countryDoc.emoji,
          countryName: values.countryName,
          countryRef,
          createdAt: Timestamp.now(),
          currencyName: countryDoc.currencyName,
          currencyRef: countryDoc.currencyRef,
          description: values.description,
          labels: values.labels,
          name: values.name,
          picture: values.picture,
          updatedAt: Timestamp.now(),
        },
      );

      onComplete(destinationRef);
    },
    [countriesCollectionRef, destinationsCollectionRef, onComplete],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <VStack alignItems="stretch" gap={4} h="100%">
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <UnorderedList>
              <ListItem>
                Country ID:
                {' '}
                {values.countryId}
              </ListItem>

              <ListItem>
                Labels:
                <Wrap>
                  {values.labels.map((l) => (<Badge>{l}</Badge>))}
                </Wrap>
              </ListItem>
            </UnorderedList>

            <Tabs>
              <TabList>
                <Tab>de</Tab>
                <Tab>en</Tab>
                <Tab>es</Tab>
                <Tab>fr</Tab>
                <Tab>it</Tab>
                <Tab>nl</Tab>
                <Tab>pl</Tab>
                <Tab>pt</Tab>
                <Tab>ru</Tab>
                <Tab>tr</Tab>
                <Tab>uk</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.de}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.de}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.de}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.en}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.en}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.en}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.es}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.es}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.es}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.fr}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.fr}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.fr}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.it}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.it}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.it}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.nl}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.nl}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.nl}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.pl}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.pl}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.pl}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.pt}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.pt}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.pt}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.ru}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.ru}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.ru}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.tr}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.tr}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.tr}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>

                <TabPanel>
                  <UnorderedList>
                    <ListItem>
                      Country Name:
                      {' '}
                      {values.countryName.uk}
                    </ListItem>

                    <ListItem>
                      Name:
                      {' '}
                      {values.name.uk}
                    </ListItem>

                    <ListItem>
                      Description:
                      {' '}
                      {values.description.uk}
                    </ListItem>
                  </UnorderedList>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <CoverFormControl
              destination={values.name.en}
              label={t('picture.label')}
              name="picture"
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
